<template>
  <v-card
    @click.passive="emitHelpMode"
    @focus.capture="emitHelpMode"
    align-start
    class="default"
    height="100%"
    elevation="1"
  >
      <v-card-text class="px-0 pb-0">
        <v-autocomplete
            :items="items"
            :value="value"
            @input="$emit('input', $event)"
            @change="$emit('modified', $event)"
            :label="label"
            :hint="hint"
            :item-disabled="itemDisabled"
            :item-value="itemValue"
            :item-text="itemText"
            :return-object="returnObject"
            persistent-hint
            :filled="filled"
            background-color="transparent"
            :clearable="clearable"
            :no-data-text="$t('noData')"
            :error-messages="
                errors.collect(
                    formScope + '.' + fieldName + rowId
                )
            "
            :data-vv-name="fieldName + rowId"
            :data-vv-scope="formScope"
            v-validate="required ? 'required' : ''"
            :required="required"
            :chips="chips"
            :deletable-chips="deletableChips"
            :multiple="multiple"
            :disabled="loading || disabled || disabledComp"
            :readonly="loading || disabled || disabledComp"
            :loading="loading"
            spellcheck="true"
            v-clear-input-on-change="!disableClearInputOnChange"
            @update:search-input="onType()"
            @blur="enableSelectAll ? setFilteredItems() : null"
            :ref="'LeaAutocomplete_' + fieldName + rowId"
            :prepend-inner-icon="prependInnerIcon"
            @click:prepend-inner="prependInnerIcon ? $emit('click:prepend-inner', $event) : null"
        >
            <template v-if="enableSelectAll" v-slot:prepend-item>
                <slot name="prependSelectAll" />
                <v-list-item
                    ripple
                    @click.stop="toggleSelectAll()"
                    v-if="showSelectAll"
                >
                    <v-list-item-action>
                        <v-icon
                            :color="value.length > 0 ? 'primary' : ''"
                            >{{ selectAllIcon }}</v-icon
                        >
                    </v-list-item-action>
                    <v-list-item-title>{{
                        $t('selectAll')
                    }}</v-list-item-title>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:append-outer>
                <v-tooltip v-if="!helpMode && companyGroupHelp && companyGroupHelp.fieldIcon" bottom color="primary">
                <template v-slot:activator="{ on }">
                    <v-btn
                    v-on="on"
                    icon
                    large
                    color="primary"
                    class="mr-3"
                    @click.prevent.capture="setHelpMode(true)"
                    >
                    <v-icon v-text="companyGroupHelp.fieldIcon" />
                    </v-btn>
                </template>
                    {{ $t('furtherInformation') }}
                </v-tooltip>
            </template>
            <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
        </v-autocomplete>
      </v-card-text>
  </v-card>
</template>

<script>
import { uniq } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
const MAIN_ENTITY = 'App\\Entity\\ProcessingActivity\\ProcessingActivity';

export default {
    name: 'LeaAutocomplete',
    inject: {
        $validator: '$validator',
    },
    data () {
        return {
            disabledComp: false,
            showSelectAll: false,
            filteredItems: []
        }
    },
    methods: {
        ...mapActions({
          setHelpMode: 'processingActivityModel/setHelpModeState',
        }),
        disable () {
            this.disabledComp = true;
        },
        enable () {
            this.disabledComp = false;
        },
        onType() {
            if (this.enableSelectAll) {
                this.showSelectAll = this.$refs['LeaAutocomplete_' + this.fieldName + this.rowId].hasDisplayedItems;
            }
        },
        setFilteredItems() {
            this.filteredItems = this.$refs['LeaAutocomplete_' + this.fieldName + this.rowId].filteredItems.filter(x => x && !Object.prototype.hasOwnProperty.call(x, 'header'));
        },
        toggleSelectAll() {
            // TODO: currently only useable when model/value is an array of objects
            this.$nextTick(() => {
                if (this.allSelected) {
                    this.$emit('input', []);
                } else {
                    let value = this.filteredItems.length
                        ? uniq([...this.value, ...this.filteredItems])
                        : uniq([...this.items.filter(x => !Object.prototype.hasOwnProperty.call(x, 'header'))]);

                    this.$emit('input', value);
                }
                this.$refs['LeaAutocomplete_' + this.fieldName + this.rowId].isFocused = false;
            });
        },
        emitHelpMode () {
            this.$emit('help-mode', {
                title: this.label,
                text: this.helpModeText,
                fieldName: this.fieldName,
                childFieldName: this.childFieldName
            })
        }
    },
    computed: {
        ...mapGetters({
            helpMode: 'processingActivityModel/getHelpModeState',
            getCompanyHelp: 'companyGroupHelp/getByEntityAndFieldName',
        }),
        companyGroupHelp() {
            return this.getCompanyHelp(MAIN_ENTITY, this.fieldName, this.childFieldName);
        },
        allSelected() {
            return this.value.length === this.items.filter(x => !Object.prototype.hasOwnProperty.call(x, 'header')).length;
        },
        someSelected() {
            return this.value.length > 0 && !this.allSelected;
        },
        selectAllIcon() {
            if (this.allSelected) return 'check_box';
            if (this.someSelected) return 'indeterminate_check_box';
            return 'check_box_outline_blank';
        }
    },
    props: {
        formScope: {
            type: String,
            default: null,
        },
        groupName: {
            type: String,
            default: null,
        },
        fieldName: {
            type: String,
            default: null,
        },
        childFieldName: {
            type: String,
            default: null,
        },
        helpModeText: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        create: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        value: {
            type: [Number, String, Object, Array],
            default: null,
        },
        items: {
          type: Array,
          default: () => []
        },
        itemValue: {
            type: Function,
            default: function(e) {
                return e.clientId && !e.id ? e.clientId : e.id
            },
        },
        itemDisabled: {
            type: [String, Function],
            default: 'disabled',
        },
        itemText: {
            type: String,
            default: 'title',
        },
        filled: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        deletableChips: {
            type: Boolean,
            default: false,
        },
        chips: {
            type: Boolean,
            default: false,
        },
        rowId: {
            type: [Number, String],
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disableClearInputOnChange: {
            type: Boolean,
            default: false,
        },
        enableSelectAll: {
            type: Boolean,
            default: false,
        },
        prependInnerIcon: {
            type: String,
            default: null,
        },
        returnObject: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        disabled (val) {
            this.disabledComp = val;
        }
    },
    created () {
        this.$emit('created');
    }
};
</script>
