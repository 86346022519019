<template>
    <div>
        <portal to="appBarNavigationTitle">
            {{ $t('vvtCreateFromTemplate.toolbarTitle') }}
        </portal>
        <portal to="appBarNavigationAction">
            <div class="d-flex flex-row justify-end align-center">
                <div>
                    <BaseBackButton :route="parentRouteObject" />
                </div>
            </div>
        </portal>
        <v-container fluid class="pl-0 pt-8">
            <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                    <div class="fixable-column">
                        <v-card
                            color="transparent"
                            class="overflow-y-auto pl-6 pr-3 pb-3"
                            flat
                            :max-height="leftColumnMaxHeight"
                        >
                             <v-card
                                v-if="selectedTemplates.length"
                                color="tertiary"
                                class="mb-6"
                                >
                                <v-card-title class="text-h5 primary--text">
                                    <v-icon large left class="primary--text text--lighten-1" v-text="'mdi-text-box-plus-outline'" />
                                    <span v-t="createGroupTemplate ? 'vvtCreateFromTemplate.selectDomains.title' : 'vvtCreateFromTemplate.selectCompanies.title'" />
                                </v-card-title>
                                <v-card-subtitle class="pt-2" v-t="createGroupTemplate ? 'vvtCreateFromTemplate.selectDomains.text' : 'vvtCreateFromTemplate.selectCompanies.text'" />
                                <v-card-actions>
                                    <LeaAutocomplete
                                        v-if="!createGroupTemplate"
                                        fieldName="selectCompanies"
                                        :label="$t('vvtCreateFromTemplate.selectCompanies.title')"
                                        :hint="$tc('vvtCreateFromTemplate.selectCompanies.hint', selectedCompanies.length * selectedTemplates.length, {amount: (selectedCompanies.length * selectedTemplates.length)})"
                                        :items="allCompanies"
                                        :item-disabled="function(e) {
                                            return !!e.canCreate === false;  
                                        }"
                                        multiple
                                        chips
                                        deletable-chips
                                        required
                                        v-model="selectedCompanies"
                                        prepend-inner-icon="mdi-home-modern"
                                        class="w100"
                                    />
                                    <LeaAutocomplete
                                        v-else
                                        fieldName="selectDomains"
                                        :label="$t('vvtCreateFromTemplate.selectDomains.title')"
                                        :hint="$tc('vvtCreateFromTemplate.selectDomains.hint', selectedDomains.length * selectedTemplates.length, {amount: (selectedDomains.length * selectedTemplates.length)})"
                                        :items="domainItems"
                                        :item-disabled="'disabled'"
                                        multiple
                                        chips
                                        deletable-chips
                                        required
                                        v-model="selectedDomains"
                                        prepend-inner-icon="mdi-select-group"
                                        class="w100"
                                    />
                                </v-card-actions>
                                <v-card-actions  v-if="(createGroupTemplate && (selectedDomains.length * selectedTemplates.length) > 250) || (!createGroupTemplate && (selectedCompanies.length * selectedTemplates.length) > 250) ">
                                    <v-alert
                                        dense
                                        text
                                        type="warning"
                                        class="mb-n1 caption"
                                        >
                                        {{ $t('vvtCreateFromTemplate.highAmountNote') }}
                                    </v-alert>
                                </v-card-actions>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="success"
                                        :disabled="(createGroupTemplate && !selectedDomains.length) || (!createGroupTemplate && !selectedCompanies.length)"
                                        @click.stop="confirmCreateMultiple()"
                                        >{{ $t('create') }}</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                            <v-card
                                v-else
                                color="tertiary"
                                hover
                                class="mb-6"
                                link
                                @click="onClickWithoutTemplate()"
                            >
                                <v-card-title class="text-h5 primary--text">
                                    <v-icon large left class="primary--text text--lighten-1" v-text="'mdi-text-box-plus-outline'" />
                                    <span v-t="'vvtCreateFromTemplate.withoutTemplate.title'" />
                                </v-card-title>
                                <v-card-subtitle class="pt-2" v-t="'vvtCreateFromTemplate.withoutTemplate.text'" />
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="success"
                                        >{{ $t('create') }}</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                            <v-card
                                color="tertiary"
                                light
                                :loading="$wait.is('fetch templates')"
                            >
                                <v-card-title class="text-h5 primary--text">
                                    <v-icon large left class="primary--text text--lighten-1" v-text="'mdi-text-box-search-outline'" />
                                    <span v-t="'vvtCreateFromTemplate.withTemplate.title'" />
                                </v-card-title>
                                <v-card-subtitle class="pt-2" v-t="'vvtCreateFromTemplate.withTemplate.text'" />
                                <v-card-actions>
                                    <SearchRecord
                                        v-model="searchTerm"
                                        disable-mask
                                        autofocus
                                        :placeholder="$t('findTemplates')"
                                        :loading="$wait.is(['fetch data', 'fetch templates'])"
                                        @input="searchRecords($event)"
                                    />
                                </v-card-actions>
                                <v-card-text v-if="filteredDomains.length">
                                    <h2 class="text-h6 mb-2" v-text="$tc('companyGroupTemplates',2)" />
                                    <v-chip-group
                                    v-model="selectedOverallCategory"
                                    column
                                    @change="selectCategory()"
                                    >
                                    <v-chip
                                        v-for="category in filteredDomains"
                                        :key="'companyGroupTemplates_' + category.id"
                                        filter
                                        outlined
                                        :value="'companyGroupTemplates_' + category.id"
                                        >{{ category.title }}
                                    </v-chip>
                                    </v-chip-group>
                                </v-card-text>
                                <v-card-text v-if="filteredDepartmentCategories.length">
                                    <h2 class="text-h6 mb-2" v-text="$tc('vvtCreateFromTemplate.leaTemplate',2)" />
                                    <v-chip-group
                                    v-model="selectedOverallCategory"
                                    column
                                    @change="selectCategory()"
                                    >
                                    <v-chip
                                        v-for="category in filteredDepartmentCategories"
                                        :key="'leaMasterTemplates_' + category.id"
                                        filter
                                        outlined
                                        :value="'leaMasterTemplates_' + category.id"
                                        >{{ category.title }}
                                    </v-chip>
                                    </v-chip-group>
                                </v-card-text>
                            </v-card>
                        </v-card>
                    </div>
                </v-col>
                <v-col cols="12" md="6" lg="8" xl="9" class="pl-9 pr-6 pt-0 pl-md-6 pr-md-0 pt-md-1">
                    <template>
                        <div
                            v-for="(categoryType, i) in itemsTree"
                            :key="i"
                        >
                            <v-container
                                fluid
                                class="py-0 pl-0 pr-0"
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <v-subheader class="primary--text px-0" :class="{'mt-3': i > 0}">
                                            <span
                                                class="text-h6 text-truncate"
                                                v-text="categoryType.category.title"
                                                />
                                                <v-spacer />
                                                <div v-if="i === 0">
                                                    <v-checkbox
                                                        v-model="selectAll"
                                                        :label="$t('selectAll')"
                                                        color="primary"
                                                        class="primary--text"
                                                    />
                                                </div>
                                        </v-subheader>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        xl="6"
                                        v-for="template in categoryType.templates"
                                        :key="template.id"
                                    >
                                            <v-hover v-slot:default="{ hover }" style="height:100%">
                                                <div>
                                                    <v-card 
                                                        class="mb-6"
                                                        :class="{'hovered': hover}"
                                                        @click="onClickTemplate(template)"
                                                        height="100%"
                                                        :color="isSelected(template) ? 'primary' : undefined"
                                                        :dark="isSelected(template)"
                                                        >
                                                        <div class="d-flex d-flex-row align-center">
                                                            <div class="pl-2 mt-n5">
                                                                <v-icon v-if="isSelected(template)" color="white" v-text="'mdi-checkbox-marked-outline'" />
                                                                <v-icon v-else :color="'grey lighten-2'" v-text="hover ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'" />
                                                            </div>
                                                            <div class="">
                                                                <v-card-subtitle class="pb-0 mb-0 pl-1" :class="{'primary--text': !isSelected(template) && hover}">{{ template.internalId }}</v-card-subtitle>
                                                                <v-card-title class="pt-0 mt-0 pl-1 mt-n1" :class="{'primary--text': !isSelected(template) && hover}">{{ template.name }}</v-card-title>
                                                            </div>
                                                            <v-card-subtitle class="pb-0 mb-0 align-self-start ml-auto text-right font-weight-thin" :class="{'primary--text': !isSelected(template) && hover}" style="width:170px;" v-text="template.leaMaster ? $tc('vvtCreateFromTemplate.leaTemplate',1) : $tc('companyGroupTemplates',1)" />
                                                        </div>
                                                        <v-card-text class="py-0 text-justify">
                                                            {{ template.description }}
                                                        </v-card-text>
                                                    </v-card>
                                                </div>
                                        </v-hover>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar
            v-model="successFeedback"
            color="success"
            :timeout="6000"
            centered
        >
            <span v-t="createGroupTemplate ? 'vvtCreateFromTemplate.selectDomains.successMessage' : 'vvtCreateFromTemplate.selectCompanies.successMessage'" />
            <v-btn dark text icon @click="successFeedback = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import http from '@/utils/axios';
import titleMixin from '@/mixins/titleMixin';
import ScrollHeaderMixin from '@/components/vvt/ScrollHeaderMixin';
import LeaAutocomplete from '@/components/Input/LeaAutocomplete';

const SearchRecord = () => import(/* webpackChunkName: "VvtSearch" */ '@/components/vvt/List/SearchRecord.vue');

export default {
    name: 'VvtTemplateSelection',
    props: {
        createGroupTemplate: {
            type: Boolean,
            default: false
        },
    },
    components: {
        SearchRecord,
        LeaAutocomplete
    },
    mixins: [titleMixin, ScrollHeaderMixin],
    i18n: {
        messages: {
            en: require('@/locales/vvt/CreateFromTemplate/en.json'),
            de: require('@/locales/vvt/CreateFromTemplate/de.json'),
        },
    },
    title() {
        return this.$t('vvtCreateFromTemplate.toolbarTitle');
    },
    data() {
        return {
            selectedOverallCategory: 'all',
            searchTerm: null,
            selectAll: false,
            selectedTemplates: [],
            selectedCompanies: [],
            selectedDomains: [],
            successFeedback: false,
            selectedType: this.$route.params.type || null,
            selectedId: this.$route.params.id ? parseInt(this.$route.params.id) : null
        };
    },
    computed: {
        ...mapGetters({
            domainItems: 'domain/getItems',
            getDepartmentCategories: 'departmentCategories/getItems',
            getEventStore: 'eventStore/getDataByKey',
            getCompanyGroupSettings: 'companyGroupSettings/get',
            templates: 'templates/getTemplates',
            currentUiLanguage: 'languageSettings/getCurrentLanguage',
            allCompanies: 'companies/getAllCompanies',
        }),
        parentRouteObject() {
            return {name: this.createGroupTemplate ? 'SettingsVvtGroupTemplatesList' : 'VvtList'};
        },
        leftColumnMaxHeight () {
            let substract = parseInt(this.$vuetify.application.top) + parseInt(this.$footerHeight());
            return this.$vuetify.breakpoint.smAndDown ? `inherit` : `calc(100vh - ${substract}px)`;
        },
        smallButton () {
            return this.$vuetify.breakpoint.smAndDown ||
                (this.$vuetify.breakpoint.md && this.$vuetify.breakpoint.width < 1020) ||
                (this.$vuetify.breakpoint.lg && this.$vuetify.breakpoint.width < 1580) ||
                (this.$vuetify.breakpoint.xl && this.$vuetify.breakpoint.width < 1930)
        },
        useOfLeaMaster () {
            return this.getCompanyGroupSettings('useOfLeaMaster');
        },
        companyGroupTemplates() {
            return this.templates.filter(x => x.domainId);
        },
        leaMasterTemplates() {
            return this.templates.filter(x => x.leaMaster);
        },
        domainIdsWithGroupTemplate() {
            return this.companyGroupTemplates.map(x => x.domainId).filter((v, i, a) => a.indexOf(v) === i);
        },
        filteredDomains () {
            return this.domainItems.filter(x => this.domainIdsWithGroupTemplate.includes(x.id));
        },
        departmentCategoryIdsWithMaster() {
            return this.leaMasterTemplates.map(x => {
                return x.departmentCategories.split(',').map(y => parseInt(y));
            }).flat().filter((v, i, a) => a.indexOf(v) === i);
        },
        filteredDepartmentCategories () {
            return this.getDepartmentCategories.filter(x => this.departmentCategoryIdsWithMaster.includes(x.id));
        },
        itemsTree() {
            let groupTemplates = [];
            this.domainIdsWithGroupTemplate.forEach(x => {
                if(!this.selectedType || this.selectedType === 'all' || (this.selectedType === 'companyGroupTemplates') && this.selectedId === x) {
                    groupTemplates.push({
                        type: 'companyGroupTemplates',
                        category: this.domainItems.find(y => y.id === x),
                        templates: this.companyGroupTemplates.filter(y => y.domainId === x)
                    });
                }
            })
            let leaMasterTemplates = [];
            this.departmentCategoryIdsWithMaster.forEach(x => {
                if(!this.selectedType || this.selectedType === 'all' || (this.selectedType === 'leaMasterTemplates') && this.selectedId === x) {
                    leaMasterTemplates.push({
                        type: 'leaMasterTemplates',
                        category: this.getDepartmentCategories.find(y => y.id === x),
                        templates: this.leaMasterTemplates.filter(y => y.departmentCategories.split(',').map(z => parseInt(z)).includes(x))
                    });
                }
            })
            return [...groupTemplates, ...leaMasterTemplates];
        }
    },
    beforeDestroy() {
        this.selectedCompanies = [];
        this.selectedDomains = [];
        this.selectedTemplates = [];
    },
    mounted () {
        this.initScrollHeader();
        if (this.getEventStore('selectedCompanyId')) {
            this.selectedCompanies = [this.getEventStore('selectedCompanyId')];
        }
        if (this.getEventStore('selectedDomainId')) {
            this.selectedDomains = [this.getEventStore('selectedDomainId')];
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        ...mapActions({
            fetchCompanyGroupSettings: 'companyGroupSettings/fetch',
            fetchDomains: 'domain/fetch',
            fetchDepartmentCategories: 'departmentCategories/fetch',
            fetchAllCompanies: 'companies/fetchAll',
            fetchSelection: 'templates/fetchSelection',
            clearModel: 'processingActivityModel/clearModel',
        }),
        searchRecords(searchTerm) {
            this.searchTerm = searchTerm;
            this.fetchFilteredTemplates();
        },
        async fetchFilteredTemplates() {
            this.$wait.start('fetch templates');
            await this.fetchSelection({
                forcedLanguage: this.currentUiLanguage,
                createGroupTemplate: this.createGroupTemplate,
                searchTerm: this.searchTerm ? this.searchTerm : null,
            });
            this.selectedType = this.$route.params.type || null;
            this.selectedId = this.$route.params.id ? parseInt(this.$route.params.id) : null;
            this.$wait.end('fetch templates');
        },
        isActive(type, id = null) {
            if (type === 'all') {
                return !this.selectedType || this.selectedType ===  'all'
            }
            return this.selectedType === type && this.selectedId === id;
        },
        categoryClassObject(type, id = null) {
            return {
                'v-list-item--active': this.isActive(type, id),
            };
        },
        selectCategory() {
            let type = null;
            let id = null;
            if(!this.selectedOverallCategory) {
                this.selectedOverallCategory = type = 'all';
            } else {
                let arrSplit = this.selectedOverallCategory.split('_');
                type = arrSplit[0];
                id = arrSplit[1];
            }

            if(this.selectedType !== type || (type !== 'all' && this.selectedType !== 'all' && parseInt(this.selectedId) !== parseInt(id))) { 
                this.$router.push({
                    name: this.$route.name,
                    params: { type, id },
                });
            }
            
        },
        confirmCreateMultiple () {
            this.$wait.start('pageLoader');

            let postData = {
                sourceRecords: this.selectedTemplates
            };
            if(this.createGroupTemplate) {
                postData.targetDomains = this.selectedDomains;
            } else {
                postData.targetCompanies = this.selectedCompanies;
            }
            http.post('/api/template/clone', JSON.stringify(postData))
                .then(response => {
                    if (!response.data.error) {
                        this.$wait.end('pageLoader');
                        this.successFeedback = true;
                        this.selectedCompanies = [];
                        this.selectedDomains = [];
                        this.selectedTemplates = [];
                        setTimeout(() => {
                            this.$router.push(this.parentRouteObject);
                        }, 2000);
                    } else {
                        this.$wait.end('pageLoader');
                    }
                })
                .catch(() => {
                    this.$wait.end('pageLoader');
                    this.selectedCompanies = [];
                    this.selectedDomains = [];
                    this.selectedTemplates = [];
                })
        },
        async fetchData() {
            this.$wait.start('fetch data');
            await this.fetchCompanyGroupSettings();
            if(this.useOfLeaMaster) {
                await this.fetchDepartmentCategories();
            }
            await this.fetchDomains();
            await this.fetchAllCompanies();
            this.$wait.end('fetch data');
            await this.fetchFilteredTemplates();
        },
        isSelected(e) {
            return this.selectedTemplates.includes(e.id);
        },
        onClickTemplate(e) {
            if (this.selectedTemplates.includes(e.id)) {
                this.selectedTemplates = this.selectedTemplates.filter(x => x !== e.id);
            } else {
                this.selectedTemplates.push(e.id);
            }
        },
        onClickWithoutTemplate() {
            this.$wait.start('pageLoader');
            this.clearModel()
                .then(() => {
                    this.$wait.end('pageLoader');
                    this.redirectToEditor();
                });
        },
        redirectToEditor() {
            if (this.createGroupTemplate) {
                this.$router.push({
                    name: 'SettingsVvtGroupTemplatesCreate',
                    query: {
                        step: 1,
                    },
                });
            } else {
                this.$router.push({
                    name: 'VvtCreateProcessingActivity',
                    query: {
                        step: 1,
                    },
                });
            }
        },
    },
    watch: {
        $route(to, from) {
            if(to.params.type !== from.params.type || (to.params.type !== 'all' && from.params.type !== 'all' && parseInt(to.params.id) !== parseInt(from.params.id))) {
                this.selectedType = to.params.type || null;
                this.selectedId = to.params.id ? parseInt(to.params.id) : null;
            }
        },
        selectAll(newVal) {
            this.selectedTemplates = [];
            if (newVal) {
                this.itemsTree.forEach(categoryType => {
                    if (categoryType.templates) {
                        categoryType.templates.forEach(template => {
                            if(!this.selectedTemplates.includes(template.id)) {
                                this.selectedTemplates.push(template.id);
                            }
                        });
                    }
                });
            }
        }
    }
};
</script>
